<template>
  <div class="content">
    <base-alert type="warning" dismissible>
      {{  $t('alerts.priorContact') }}
    </base-alert>
    <base-alert icon="tim-icons icon-lock-circle" v-if="this.$store.state.user.demo" type="warning" dismissible>
      {{  $t('search.demo.disabledFeatures') }}
    </base-alert>
    <div class="row">
      <div class="col-md-6">
        <card class="stacked-form"><h3 class="card-title"> {{ $t('alerts.add') }}
          <b>{{ this.$store.getters.getCurrentOrganisation.name }}</b>
        </h3>
          <br>
          <form @submit.prevent="addAlert">
            <div>
              <base-input :label="$t('alerts.qry')" :placeholder="$t('alerts.input')" v-model="newAlert"></base-input>
              <base-button class="mt-3" native-type="submit" type="primary">
                {{ $t('alerts.btnAdd') }}
              </base-button>
            </div>
          </form>
        </card>
      </div>
      <div class="col-md-6">
        <card class="stacked-form">
          <h3 class="card-title"> {{ $t('alerts.import') }}
          </h3>
          <br>
          <div class="d-flex justify-content-around">
            <file-upload @change="onFileUpload" :select-text="$t('alerts.upload')" mime="text/csv"/>
            <base-button class="mt-3" @click="downloadCSVExample"><i class="tim-icons icon-cloud-download-93"></i>
              {{ $t('alerts.dwnldExample') }}
            </base-button>
          </div>
        </card>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ $t('alerts.list') }}</h4>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                ></el-input>
              </base-input>
            </div>
            <el-table id="mainarr" :data="queriedData">
              <el-table-column
                sortable
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              ></el-table-column>
              <el-table-column :label="$t('alerts.user')" :minWidth="250" sortable>
                <template slot-scope="props">
                  {{ getCurrentUserMail() }}
                </template>
              </el-table-column>
              <el-table-column :min-width="135" align="right" :label="$t('alerts.actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BaseAlert, BasePagination} from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {refreshContext, formatDate} from "@/utils";
import {mapMutations} from 'vuex';
import FileUpload from '../../components/FileUpload.vue';

export default {
  components: {
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    FileUpload
  },
  computed: {
    queriedData() {
      let result = this.alerts;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.alerts.length;
    }
  },
  data() {
    return {
      token: localStorage.getItem("userToken") || "",
      files: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      newAlert: "",
      searchQuery: '',
      propsToSearch: ['query_terms', /*'user_id', 'organisation_id',*/ 'last_request', 'last_status'],
      tableColumns: [
        {
          prop: 'query_terms',
          label: this.$t('alerts.qryTerms'),
          minWidth: 250
        },
        {
          prop: 'last_request',
          label: this.$t('alerts.lastRequest'),
          minWidth: 120
        },
        {
          prop: 'last_results',
          label: this.$t('alerts.lastResults'),
          minWidth: 120
        },
        {
          prop: 'last_status',
          label: this.$t('alerts.lastStatus'),
          minWidth: 120
        }
      ],
      alerts: this.getAlerts() || [],
      searchedData: [],
      fuseSearch: null,
      upload: null,
    };
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'switchOrganisation':
          this.alerts = this.getAlerts() || [];
          this.pagination.currentPage = 1;
          this.searchedData = [];
          break;
      }
    });
    this.fuseSearch = new Fuse(this.alerts, {
      keys: ['name', 'email'],
      threshold: 0.3
    });
  },
  methods: {
    ...mapMutations([
      'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
    ]),
    onFileUpload(file) {
      console.log(file);
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const fetchOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          body: formData,
        };
        console.log(fetchOptions);
        const apiUrl = this.$apiEndpoint + `/api/alerts/csv`;
        fetch(apiUrl, fetchOptions)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else if (response.status === 401) {
              this.logout();
              throw new Error("Accès non autorisé. Veuillez-vous connecter.");
            } else if (response.status === 409) {
              swal.fire({
                title: this.$t('errors.alreadyExists'),
                text: this.$t('errors.backend.AlreadyExistsAlertQuery'),
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill'
                },
                icon: 'error'
              });
            } else {
              throw new Error("Unauthorized resource");
            }
          })
          .then((data) => {
            data.forEach(d => {
              this.alerts.push({
                "id": d.id,
                "query_terms": d.query_terms,
                // "email": "damien.lescos@sitincloud.com",
                "last_status": d.last_status,
                "last_request": formatDate(d.last_request),
                "last_results": d.last_results,
              });
              return true;
            });
          })
      }
    },
    getCurrentUserMail() {
      return this.$store.state.user.email
    },
    getOrganisationName(organisation_id) {
      return this.$store.getters.getOrganisationName(organisation_id)
    },
    downloadCSVExample() {
      // console.log(this.$store.state.account.name + `";"` + this.$store.getters.getCurrentOrganisation.name);
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += `"Query terms";"Organisation ID"\n`;
      csvContent += `"` + this.$store.state.account.name + `";"` + this.$store.getters.getCurrentOrganisation.id + `"\n`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "results.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$notify({
        type: 'primary',
        message: "Téléchargement en cours...",
        icon: 'tim-icons icon-cloud-download-93'
      });
    },
    getAlerts() {
      this.token = localStorage.getItem("userToken") || "";
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/alerts/`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          let returnTable = [];
          data.forEach(d => {
            returnTable.push({
              "id": d.id,
              "query_terms": d.query_terms,
              // "email": "damien.lescos@sitincloud.com",
              "last_status": d.last_status,
              "last_request": formatDate(d.last_request),
              "last_results": d.last_results,
            });
          });
          this.alerts = returnTable;
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: `${ this.$t('errors.error') } - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    addAlert() {
      if (this.newAlert) {
        if (this.alerts.some((alert) => alert.query === this.newAlert)) {
          return false;
        } else {
          const fetchOptions = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query_terms: this.newAlert,
              last_status: 'new',
              last_results: 0,
              organisation_id: this.$store.getters.getCurrentOrganisation.id
            }),
          };
          console.log(fetchOptions.body);
          const apiUrl = this.$apiEndpoint + `/api/alerts/`;
          fetch(apiUrl, fetchOptions)
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else if (response.status === 401) {
                this.logout();
                throw new Error("Accès non autorisé. Veuillez-vous connecter.");
              } else if (response.status === 409) {
                swal.fire({
                  title: this.$t('errors.alreadyExists'),
                  text: this.$t('errors.backend.AlreadyExistsAlertQuery'),
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-success btn-fill'
                  },
                  icon: 'error'
                });
              } else {
                throw new Error("Unauthorized resource");
              }
            })
            .then((data) => {
              this.alerts.push(data);
              this.newAlert = "";
              return true;
            });
        }
      }
    },
    removeAlert(index, row) {
      const fetchOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/alerts/` + row.id;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            this.alerts.splice(index, 1);
            swal.fire({
              title: this.$t('alerts.deleted'),
              text: this.$t('alerts.youDeleted') + " " + row.query_terms,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .catch((error) => {
          console.error("Error removing alert:", error);
        });
    },
    editAlert(index, row, newQuery) {
      const fetchOptions = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: row.id,
          query_terms: newQuery,
          last_status: 'new',
          organisation_id: this.$store.getters.getCurrentOrganisation.id,
          last_request: null,
          last_results: 0,
        }),
      };
      console.log(fetchOptions.body);
      const apiUrl = this.$apiEndpoint + `/api/alerts/`; // + row.id;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else if (response.status === 409) {
            swal.fire({
              title: this.$t('errors.alreadyExists'),
              text: this.$t('errors.backend.AlreadyExistsAlertQuery'),
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              icon: 'error'
            });
            this.handleEdit(index, row);
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          this.alerts[index].id = data.id;
          this.alerts[index].query_terms = data.query_terms;
          this.alerts[index].last_status = data.last_status;
          this.alerts[index].last_results = data.last_results;
          this.alerts[index].last_request = formatDate(data.last_request);
        });
    },
    handleEdit(index, row) {
      swal.fire({
        title: this.$t('alerts.youEdit') + " " + row.query_terms,
        input: "text",
        inputPlaceholder: row.query_terms,
        inputValue: row.query_terms,
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        buttonsStyling: false
      })
        .then((result) => {
          if (this.alerts.some((alert) => alert.query === result.value)) {
            swal.fire({
              title: this.$t('errors.alreadyExists'),
              text: this.$t('errors.backend.AlreadyExistsAlertQuery'),
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              icon: 'error'
            });
          }
          if (result.value && result.value != row.query_terms) {
            this.editAlert(index, row, result.value)
          }
        })
        .catch(swal.noop);
    },
    handleDelete(index, row) {
      swal.fire({
        title: this.$t('search.popup.confrm'),
        text: this.$t('search.popup.cantRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          // this.deleteRow(row);
          this.removeAlert(index, row);
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.alerts.findIndex(tableRow => tableRow.id === row.id);
      if (indexToDelete >= 0) {
        this.alerts.splice(indexToDelete, 1);
      }
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.alerts;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

#mainarr th {
  background-color: unset;
}
</style>
