<template>
  <div class="container">
    <div class="row justify-center">
      <div class="col-lg-12 col-sm-8 col-md-6">
        <div v-if="showHomePage" class="col center-container">
          <div class="home-page">
            <div class="home-page-items">
              <img
                src="https://assets-global.website-files.com/65463160950bc3900a5fbc0f/65463390bfc56f4a35195fd7_OwlyScan%20Logo.svg"
                alt="OwlyScan" class="home-page-image"/>
              <p class="home-page-title">OwlyScan</p>
              <br/>
              <h2>{{ $t('search.subtitle') }}</h2>
              <br/>
            </div>
          </div>
        </div>
        <div class="search-bar" id="searchBar" flat>
          <div class="search-bar-title d-flex align-center justify-center col-md-6 ml-auto mr-auto">
            <input required v-model="search" :placeholder="$t('search.placeholder')" single-line hide-details
                   type="text"
                   @keyup.enter="startSearch" :disabled="loading" :error="errors[0]" class="search-input flex-grow-1"
                   id="search-input" autocomplete="off"/>
            <base-button native-type="submit" type="primary" @click.native="startSearch" id="search-button">
              <i class="tim-icons icon-zoom-split"></i>{{ $t('search.lblBtnSearch') }}
            </base-button>
            <filter-search-button :propExclusions="exclusions" :propStartDate="startDate"
                                  :propEndDate="endDate" @apply-filters="applyFilters"></filter-search-button>
          </div>
        </div>
        <div v-if="showHomePage" class="col center-container">
          <div class="home-page">
            <div class="home-page-items">
              <p>{{ $t('search.disclaimer') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-container" v-if="loading">
      <div class="loading-text">{{ $t('search.loading') }}</div>
    </div>
    <div class="spinner-container" v-if="loading">
      <div class="spinner"></div>
    </div>
    <div v-if="error" class="error">Error: {{ error }}</div>
    <div class="row" v-if="searchQuery && !loading">
      <div class="col" cols="12">
        <div v-if="searchPerformed && totalResults === 0" class="no-results-found">
          {{ $t('search.noResult') }} "{{ searchQuery }}"
          <div v-if="isFilterModified || dateFilterApplied || exclusions.length > 0"
               class="applied-filters">
            {{ $t('search.filtersApplied') }}
            <el-tag type="primary" v-for="(exclusion, index) in exclusions" :key="index" closable icon small
                    @close="removeExclusion(exclusion)">
              {{ exclusion }}
            </el-tag>
            <el-tag type="primary" closable v-if="startDate != '' && startDate != null" label @close="resetStartDate">
              {{ $t('search.filters.from') }} {{ formatDate(startDate) }}
            </el-tag>
            <el-tag type="primary" closable v-if="endDate != '' && endDate != null" label @close="resetEndDate">
              {{ $t('search.filter.until') }} {{ formatDate(endDate) }}
            </el-tag>
            <base-button v-if="isFilterModified" type="primary" simple round icon @click="restartSearch">
              <i class="tim-icons icon-refresh-01"></i>
            </base-button>
          </div>
        </div>
        <sidebar-search-tools :doublons="doublons" :results="apiResults.hits" @filter-url="applyFilterByUrl"
                              @unhide-all="unhideAll" @unhide="uncheckItem"></sidebar-search-tools>
        <div v-if="searchQuery && !loading && totalResults" class="search-result-message">
          <div>
            <span class="search-result-count">{{ totalResults.toLocaleString() }}</span>
            <span class="search-result-text">  {{ $t('search.results') }} "</span>
            <span class="search-result-query">{{ searchQuery }}</span>
            <span class="search-result-text">".</span>
          </div>
          <p>{{ $t('search.displayed') }} {{ apiResults.hits.length }}</p>
          <div v-if="isFilterModified || dateFilterApplied || exclusions.length > 0"
               class="applied-filters">
            {{ $t('search.filtersApplied') }}
            <el-tag type="primary" v-for="(exclusion, index) in exclusions" :key="index" label closable icon small
                    @close="removeExclusion(index)">
              {{ exclusion }}
            </el-tag>
            <el-tag type="primary" v-if="startDate != '' && startDate != null" closable label @close="resetStartDate">
              {{ $t('search.filters.from') }} {{ formatDate(startDate) }}
            </el-tag>
            <el-tag type="primary" v-if="endDate != '' && endDate != null" closable label @close="resetEndDate">
              {{ $t('search.filters.until') }} {{ formatDate(endDate) }}
            </el-tag>
            <base-button v-if="isFilterModified" type="primary" simple round icon @click="restartSearch">
              <i class="tim-icons icon-refresh-01"></i>
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentFilteredUrl" class="row result-for-url-header">
      <div class="col">
        <el-tag type="primary" closable label close @click="resetToGlobalResults" @close="resetToGlobalResults">
          {{ $t('search.results') }} {{ currentFilteredUrl }}
        </el-tag>
      </div>
    </div>
    <div class="row" v-if="searchQuery && !loading">
      <div class="col-lg-12" v-for="(item, index) in apiResults.visible" :key="item.id" v-show="!item.checked">
        <card v-if="!item.checked" class="search-result-card">
          <div class="url-description-block">
            <div class="url-block">
              <div v-if="!item.checked" class="search-result-url" @click="openLink(item.url)"
                   @mouseover="item.hovered = true" @mouseleave="item.hovered = false">
                {{ item.hovered ? item.url : shortenUrl(item.url) }}
              </div>
              <div class="icons-right d-flex align-center">
                <base-button type="primary" class="icon-url equal-width-chips no-margin-chips" v-if="item.checked" label
                             outlined color="#ee5a2f" @click="toggleChecked(item)">
                  {{ $t('search.show') }}
                </base-button>
                <base-button type="primary" round icon v-else class="icon-url equal-width-chips no-margin-chips" label
                             outlined @click="toggleChecked(item)">
                  <i class="icon-without-background fa fa-2x fa-eye-slash"></i>
                </base-button>
                <base-button type="primary" round icon v-if="!item.checked"
                             class="icon-url equal-width-chips clipboard-btn" outlined
                             @click="copyToClipboard(item.url)">
                  <i class="icon-without-background fa fa-2x fa-copy"></i>
                </base-button>
                <template v-if="!item.checked && item.url.toLowerCase() !== currentFilteredUrl">
                  <base-button type="primary" simple class="occurrences-chip icon-url" label outlined
                               @click="showOccurrences(item.url)" style="color: white">
                    {{ getOccurrences(item.url) }} x
                  </base-button>
                </template>
              </div>
              <template v-if="!item.checked && currentFilteredUrl === item.url.toLowerCase()">
                <base-button type="primary" simple style="color: white" label outlined>
                  {{ index + 1 }}/{{ totalFilteredOccurences }}
                </base-button>
              </template>
            </div>
            <div class="description-block">
              <span class="search-result-file" v-if="item.file && item.file.length > 1">{{ item.file.startsWith('/') ? item.file : '/' + item.file }}</span>
              <div v-if="!item.checked" class="search-result-description"
                   v-html="findTextAroundKeyword(item.text, searchQuery)"></div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row pagination-row"
         v-if="searchQuery && !loading && !viewingOccurrences && apiResults.hits.length < totalResults">
      <ul class="pagination">
        <li>
          <base-button type="primary" round @click="loadMoreContent">
            {{ $t('search.loadMore') }}<i class="tim-icons icon-simple-add"></i>
          </base-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import {Tag, DatePicker, Select, Option} from 'element-ui';
import SidebarSearchTools from "./SidebarSearchToolsPlugin.vue";
import FilterSearchButton from "./FilterSearchButtonPlugin.vue";
import {refreshContext, formatDate} from "@/utils";
import swal from 'sweetalert2';
// import ScrollToTopButton from "./ScrollToTopButtonPlugin.vue";


export default {
  name: "DarknetSearch",
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tag.name]: Tag,
    SidebarSearchTools,
    FilterSearchButton,
  },
  mounted() {
    refreshContext(this.$store);
    this.checkUrlParameter();
  },
  data() {
    return {
      search: "",
      apiResults: {
        hits: [],
        visible: [],
      },
      errors: [],
      visibleHits: [],
      currentFilteredUrl: "",
      totalFilteredOccurences: 0,
      loading: false,
      error: null,
      searchQuery: "",
      showHomePage: true,
      searchPerformed: false,
      uniqueUrls: new Set(),
      groupedResults: new Map(),
      totalResults: 0,
      exclusions: JSON.parse(localStorage.getItem("exclusions")) || [],
      currentPage: 1,
      resultsPerPage: 20,
      viewingOccurrences: false,
      startDate: localStorage.getItem("startDate") || "",
      endDate: localStorage.getItem("endDate") || "",
      dateFilter: "",
      maxVisiblePages: 1,
      clicksOnSeeMore: 0,
      start_offset: 0,
      maxHits: 500,
      token: localStorage.getItem("userToken") || "",
      errorLoginMessage: "",
      visiblePassword: false,
      timerCount: 30,
      isFilterModified: false,
      tokenCount: 1,
      loadMore: false,
      consume: 0
    };
  },
  computed: {
    dateFilterApplied() {
      return this.startDate !== "" || this.endDate !== "";
    },
    displayedPages() {
      let MAX_PAGE = 8;
      let endPage = this.currentPage + Math.floor(MAX_PAGE / 2);
      let startPage = this.currentPage - Math.floor(MAX_PAGE / 2);
      let pages = [1];
      for (let page = Math.max(2, startPage); page <= endPage; page++) {
        pages.push(page);
      }
      pages.sort((a, b) => a - b);
      return pages;
    },
    uniqueResults() {
      return this.apiResults.visible.filter(
        (hit) =>
          !this.uniqueUrls.has(hit.url) ||
          (!hit.checked)
      );
    },
    doublons() {
      return Array.from(this.groupedResults.entries()).filter(
        ([, items]) => items.length > 1
      );
    },
  },
  methods: {
    checkUrlParameter() {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      if (code === '42') {

        this.$notify({
          message:
            this.$t('buy.paymentintent_successful'),
          timeout: 30000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });

        this.removeUrlParameter();
      }
    },
    removeUrlParameter() {
      const url = new URL(window.location);
      url.searchParams.delete('code');
      window.history.pushState({}, '', url);
    },
    required(v) {
      return !!v || "Field is required";
    },
    performSearch() {
      this.timerCount = 30;
      if (this.search.trim()) {
        let dateFilter = "";
        if (this.startDate != "" && this.startDate != null) {
          dateFilter += " date:>=" + this.startDate;
        }
        if (this.endDate != "" && this.endDate != null) {
          dateFilter += " date:<=" + this.endDate;
        }
        if (this.search != this.searchQuery || dateFilter != this.dateFilter || this.isFilterModified || !this.loadMore) {
          this.resetSearch();
        }
        if (this.consume == 1) {
          if (this.$store.getters.getCurrentOrganisation.tokens_q <= 0) {
            swal.fire({
              title: `Insufficient balance!`,
              text: 'Please recharge your tokens.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-error btn-fill'
              },
              icon: 'error'
            });
            console.log("Insufficient balance");
            return;
          }
        }
        this.dateFilter = dateFilter;
        this.clicksOnSeeMore = 0;
        this.showHomePage = false;
        this.searchPerformed = true;
        this.searchQuery = this.search;
        this.loading = true;
        this.error = null;
        this.loadMore = false;
        this.isFilterModified = false;
        const fetchOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
        const apiUrl = this.$apiEndpoint + `/api/search`;
        const params = new URLSearchParams({
          query: this.searchQuery + dateFilter,
          max_hits: this.maxHits,
          start_offset: this.start_offset,
          org_id: this.$store.getters.getCurrentOrganisation.id,
        }).toString();
        fetch(`${apiUrl}?${params}`, fetchOptions)
          .then((response) => {
            if (response.ok) {
              if (response.status === 204) {
                swal.fire({
                  title: this.$t("search.popup.firstSearch"),
                  text:  this.$t("search.popup.notDebited"),
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-info btn-fill'
                  },
                  icon: 'info'
                });
              }
              return response.json();
            } else if (response.status === 401) {
              this.logout();
              throw new Error("Unauthorized access. Please login.");
            } else if (response.status === 429) {
              throw new Error("Too Many Requests");
            } else {
              throw new Error("Unauthorized resource");
            }
          })
          .then((data) => {
            data.hits.forEach(d => {
              this.apiResults.hits.push(
                {
                  "date": d.date,
                  "file": d.file,
                  "hash": d.hash,
                  "text": d.text,
                  "url": d.url,
                  "checked": false,
                  "hovered": false,
                }
              )
            });
            this.processSearchResults(this.apiResults.hits);
            this.totalResults = data.num_hits;
            // if (this.totalResults == 0) {
            //   swal.fire({
            //     title: `Search unsuccessful!`,
            //     text: `Your account has not been debited.`,
            //     buttonsStyling: false,
            //     customClass: {
            //       confirmButton: 'btn btn-info btn-fill'
            //     },
            //     icon: 'info'
            //   });
            // }
          })
          .catch((error) => {
            this.error = error.message;
          })
          .finally(() => {
            this.loading = false;
            this.consume = 0;
            refreshContext(this.$store);
          });
      } else {
        this.search = "";
        this.resetSearch();
      }
    },
    showSwal() {
      const swalBeforeSearch = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalBeforeSearch
        .fire({
          title: this.$t('search.popup.confirm'),
          text: this.$t('search.popup.consumeAToken'),
          confirmButtonText: this.$t('search.popup.btnConfirm'),
          cancelButtonText: this.$t('search.popup.btnCancel'),
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            const swalWithBootstrapButtons5 = swal.mixin({
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            swalWithBootstrapButtons5.fire({
              title: this.$t('search.popup.launched'),
              text: this.$t('search.popup.searchIsLaunched'),
            });
            this.performSearch();
          }
        });
    },
    startSearch() {
      this.resetSearch();
      this.search = this.search.replaceAll("@", "");
      if (this.search.trim()) {
        this.showSwal();
      }
    },
    removeExclusion(exclusion) {
      this.exclusions.splice(this.exclusions.indexOf(exclusion), 1);
      this.isFilterModified = true;
    },
    resetStartDate() {
      this.startDate = "";
      this.isFilterModified = true;
    },
    resetEndDate() {
      this.endDate = "";
      this.isFilterModified = true;
    },
    restartSearch() {
      this.isFilterModified = true;
      this.saveFilters();
      this.performSearch();
    },
    saveFilters() {
      localStorage.setItem("exclusions", JSON.stringify(this.exclusions));
      localStorage.setItem("startDate", this.startDate);
      localStorage.setItem("endDate", this.endDate);
    },
    applyFilters(exclusions, startDate, endDate) {
      this.isFilterModified = (JSON.stringify(this.exclusions) !== JSON.stringify(exclusions)) || this.startDate !== startDate || this.endDate !== endDate;
      this.exclusions = exclusions;
      this.startDate = startDate;
      this.endDate = endDate;
      this.saveFilters();
    },
    shortenDescription(description) {
      const MAX_LENGTH = 50;
      if (description.length > MAX_LENGTH) {
        const shortenedDescription = `${description.substring(
          0,
          10
        )}...${description.substring(description.length - 10)}`;
        return shortenedDescription;
      }
      return description;
    },
    shortenUrl(url) {
      const MAX_LENGTH = 50;
      if (url.length <= MAX_LENGTH) {
        return url;
      }
      const shortenedUrl = url.substr(0, 35) + "..." + url.substr(-35);
      return shortenedUrl;
    },
    updateVisibleHits() {
      this.visibleHits = this.apiResults.visible.filter(
        (item) => !item.checked
      );
    },
    toggleChecked(item) {
      item.checked = !item.checked;
      this.updateVisibleHits();
    },
    processSearchResults(hits) {
      hits = hits.filter((hit) => {
        const customExclusion =
          this.exclusions.length > 0
            ? !this.exclusions.some((exclusion) =>
              new RegExp(exclusion, "i").test(hit.url)
            )
            : true;
        return customExclusion;
      });
      this.groupResultsByURL(hits);
      this.updateVisibleHits();
    },
    groupResultsByURL(hits) {
      const groupedResults = new Map();
      hits.forEach((hit) => {
        const normalizedUrl = hit.url.toLowerCase();
        const hitsList = groupedResults.get(normalizedUrl) || [];
        groupedResults.set(normalizedUrl, [...hitsList, hit]);
      });
      this.groupedResults = groupedResults;
      this.apiResults.visible = hits.filter(
        (hit, index, self) =>
          index ===
          self.findIndex((t) => t.url.toLowerCase() === hit.url.toLowerCase())
      );
    },
    // formatDate(date) {
    //   return date ? new Date(date).toLocaleDateString() : "";
    // },
    resetToGlobalResults() {
      this.currentFilteredUrl = "";
      this.totalFilteredOccurences = 0;
      this.apiResults.visible = [...this.groupedResults.values()]
        .flat()
        .filter(
          (hit, index, self) =>
            index ===
            self.findIndex((t) => t.url.toLowerCase() === hit.url.toLowerCase())
        );
      this.searchPerformed = true;
      this.viewingOccurrences = false;
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    getOccurrences(url) {
      const normalizedUrl = url.toLowerCase();
      if (this.groupedResults.has(normalizedUrl)) {
        return this.groupedResults.get(normalizedUrl).length;
      }
      return 0;
    },
    findTextAroundKeyword(fullText, searchQuery, charsBeforeAfter = 200) {
      if (!searchQuery.trim()) {
        return fullText;
      }
      let snippet = "";
      let keywords = searchQuery.split(" ");
      for (let i = 0; i < keywords.length; i++) {
        let keyword = keywords[i].trim();
        if (keyword != "") {
          const escapedSearch = keyword.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
          const regex = new RegExp(`(${escapedSearch})`, "ig");
          let match = regex.exec(fullText);
          if (match) {
            const startIndex = Math.max(match.index - charsBeforeAfter, 0);
            const endIndex = Math.min(
              match.index + match[0].length + charsBeforeAfter,
              fullText.length
            );
            snippet += startIndex > 0 ? "..." : "";
            snippet += fullText.substring(startIndex, endIndex).trim();
            snippet += endIndex < fullText.length ? "..." : "";
          } else {
            return fullText;
          }
          snippet = snippet.replace(
            regex,
            `<span style="color: #ed592e;">$1</span>`
          );
        }
      }
      return snippet;
    },
    applyFilterByUrl(url) {
      const results = this.groupedResults.get(url.toLowerCase());
      if (results) {
        this.apiResults.visible = results;
        this.currentFilteredUrl = url.toLowerCase();
        this.totalFilteredOccurences = results.length;
      } else {
        this.apiResults.visible = [];
        this.currentFilteredUrl = "";
        this.totalFilteredOccurences = 0;
      }
    },
    showOccurrences(url) {
      const normalizedUrl = url.toLowerCase();
      if (this.groupedResults.has(normalizedUrl)) {
        this.currentFilteredUrl = normalizedUrl;
        this.apiResults.visible = this.groupedResults.get(normalizedUrl);
        this.totalFilteredOccurences = this.apiResults.visible.length;
        this.viewingOccurrences = true;
      } else {
        this.apiResults.visible = [];
        this.currentFilteredUrl = "";
        this.totalFilteredOccurences = 0;
        this.viewingOccurrences = false;
      }
    },
    uncheckItem(item) {
      item.checked = false;
    },
    unhideAll() {
      this.apiResults.visible.forEach((hit) => {
        hit.checked = false;
      });
    },
    performQuickSearch(term) {
      this.search = term;
      this.performSearch();
    },
    resetSearch() {
      this.searchQuery = "";
      this.totalResults = 0;
      this.currentPage = 1;
      this.clicksOnSeeMore = 0;
      this.apiResults.visible = [];
      this.apiResults.hits = [];
      this.loading = false;
      this.error = null;
      this.showHomePage = true;
      this.maxVisiblePages = 1;
      this.consume = 1;
    },
    copyToClipboard(text) {
      const clipboard = new ClipboardJS(".clipboard-btn", {
        text: function () {
          return text;
        },
      });
      clipboard.on("success", function (e) {
        this.$notify({
          type: 'primary',
          message: "Copied to clipboard!",
          icon: 'tim-icons icon-satisfied'
        });
        console.log("Copied to clipboard:", e.text);
        e.clearSelection();
      });
      clipboard.on("error", function (e) {
        console.error("Error copying to clipboard:", e.text);
      });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.scrollToTop();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    goToPage(pageNumber) {
      if (pageNumber > this.maxVisiblePages) {
        this.start_offset += this.maxHits;
        this.performSearch();
      }
      this.currentPage = pageNumber;
    },
    loadMoreContent() {
      this.start_offset += this.maxHits;
      this.loadMore = true;
      this.performSearch();
      this.$notify({
        type: 'primary',
        message: "Additional content found!",
        icon: 'tim-icons icon-light-3',
        timeout: 10000
      });
    },
    refreshPage() {
      location.reload();
    },
    buyTokens() {
      alert("Unlimited usage");
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
/* Responsive styles */
@media only screen and (max-width: 600px) {
  .search-result-url {
    font-size: 0.8rem;
  }

  .search-result-description {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .search-result-url {
    font-size: 1rem;
  }

  .search-result-description {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1201px) {
  .search-result-url {
    font-size: 1.125rem;
  }

  .search-result-description {
    font-size: 1rem;
  }
}

/* General styles */
* {
  font-weight: 300;
}

.eye-icon {
  color: #ed592e;
  cursor: pointer;
}

.url-description-block {
  display: block;
  width: 100%;
  height: auto;
}

.url-block {
  display: flex;
  flex-wrap: nowrap;
  white-space: wrap;
  align-items: center;
}

.search-bar {
  background-color: transparent;
  margin-top: 25px;
  margin-bottom: 50px;
}

.search-input {
  border-radius: 8px 0px 0px 8px;
  border: rgb(214, 214, 214) solid 1px;
  background-color: transparent;
  color: white;
  font-size: 18px;
  padding: 5px;
  padding-left: 15px;
}

#search-button {
  padding: 10px 20px 10px 20px;
  margin-right: 20px;
  margin-left: 0;
  gap: 10px;
  border-radius: 0px 8px 8px 0px;
}

.error {
  color: #ee5a2f;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.search-result-card {
  width: auto;
  background-color: transparent;
  margin: auto;
}

.search-result-url {
  display: flex;
  color: white;
  cursor: pointer;
  flex-grow: 2;
  margin: 2px;
  font-size: 1.125rem;
  padding: 0 45px 0 25px;
  height: auto;
  margin-bottom: 2px;
}

.search-result-card:hover .search-result-url {
  color: #ed592e;
}

.search-result-description {
  color: rgb(216, 216, 216);
  font-size: 1rem;
  position: relative;
  padding: 0 0 20px 25px;
  margin-top: 2px;
}

.search-result-file {
  padding: 0 0 20px 25px;
  color: #6c757d;
}

.search-result-message {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: left;
  color: rgb(255, 255, 255);
}

.no-results-found {
  padding: 10px;
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: lighter;
  text-align: center;
  color: #ffffff;
}

.result-for-url-header {
  color: #ffffff;
  margin: auto;
  width: 70rem;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: auto;
}

.no-margin-chips {
  margin-right: 0;
}

.home-page {
  color: white;
  text-align: center;
  margin: auto;
  position: relative;
  top: 5rem;
  margin-bottom: 50px;
}

.home-page-image {
  width: 120px;
}

.home-page-title {
  font-size: 42px;
  font-weight: 600;
}

.home-page h1 {
  font-weight: lighter;
}

.lowercase-text {
  text-transform: none !important;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
  border: 5px solid transparent;
  border-top: 5px solid #ee5a2f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
}

.pagination-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
