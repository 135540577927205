<template>
    <div class="container">
        <div class="col-lg-4 col-md-6 ml-auto mr-auto">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
                    <card id="form-reset-password" class="mb-0">
                        <template slot="header">
                            <h4 class="card-title" v-if="title">{{ title }}</h4>
                        </template>
                        <div>
                            <ValidationProvider :name="$t('input.code')" rules="required" v-slot="{ passed, failed, errors }">
                                <base-input required v-model="giftCode" :placeholder="$t('input.email')"
                                    addon-left-icon="tim-icons icon-gift-2" :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]" type="text"></base-input>
                            </ValidationProvider>
                        </div>
                        <base-alert type="danger" dismissible v-if="errorMessage != ''">
                            <span><b>{{ $t('errors.error') }} - </b>{{errorMessage}}</span>
                        </base-alert>
                        <div slot="footer">
                            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
                                {{ $t('giftCode.btnSubmit') }}
                            </base-button>
                            <div class="pull-left">
                                <h6>
                                    <router-link class="link footer-link" to="/login"> {{
                                        $t('giftCode.redirectionLogin')
                                    }}
                                    </router-link>
                                </h6>
                            </div>
                            <div class="pull-right">
                                <h6>
                                    <router-link class="link footer-link" to="/reset-password"> {{
                                        $t('giftCode.redirectionResetPassword')
                                    }}
                                    </router-link>
                                </h6>
                            </div>
                        </div>
                    </card>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);
import { BaseAlert } from 'src/components';
import swal from "sweetalert2";

export default {
    components: {
        BaseAlert
    },
    data() {
        return {
            giftCode: "",
            errorMessage: ""
        };
    },
    computed: {
        title() {
            return `${this.$route.name}`;
        }
    },
    methods: {
        async submit() {
            try {
                const response = await fetch(this.$apiEndpoint + `/api/gift/` + this.giftCode, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                const responseBody = await response.json(); // This will work for both success and error responses
                if (!response.ok) {
                    // If the response is not okay, throw an error with the response body
                    throw responseBody;
                }
                swal.fire({
                    title: `${this.$route.name}`,
                    text: this.$t("giftCode.codeUsed")+"\n"+this.$t("giftCode." + responseBody.message),
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-success btn-fill',
                    },
                    icon: 'success',
                });
            } catch (error) {
                const errorMessage = this.$t("errors.backend." + error.detail) || 'Unknown error occurred';
                swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: errorMessage,
                    customClass: {
                        confirmButton: 'btn btn-danger btn-fill',
                    },
                    buttonsStyling: false,
                });
            }
        }
    }
}
</script>
<style>
.navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
}

.card .alert {
    position: relative !important;
    width: 100%;
}

#form-reset-password {
    background-color: white;
    color: black;
    border-radius: 20px;
}

#form-reset-password h4 {
    color: black;
    text-align: center;
    font-weight: bold;
    font-size: 2em;
}

#form-reset-password i {
    color: black;
}

#form-reset-password input {
    color: black;
}

.modal-body,
.modal-footer {
    padding: 24px 24px 16px 24px;
}
</style>
  